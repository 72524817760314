import React from 'react';

function Footer() {
    return (
        <footer class="border-top footer text-muted">
            <div class="container-fluid">
                &copy; 2025 - GaugeStage.com - a <a href="https://timbob.com/" target="_blank" rel="noreferrer">TimBob.com</a> Project.
            </div>
        </footer>
    );
}

export default Footer;